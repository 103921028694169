import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { renderToStaticMarkup } from 'react-dom/server';

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./home.css";
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";

// import soru ile compenant soru sirasinin aynı olması onemli yoksa slayt bozuluyor
import Carousel from "../components/Carousel";

import { EffectFade, Navigation, Pagination, Keyboard } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";


const Hipurin = () => {
  const [posts, setPosts] = useState([]);
  //const [postsh, setPostsh] = useState([]);
  const [sonuc01goster, setSonuc01] = useState([]);
  const [sonuc02goster, setSonuc02] = useState([]);
  const [sonuc031goster, setSonuc031] = useState([]);
  const [sonuc032goster, setSonuc032] = useState([]);
  const [sonuc033goster, setSonuc033] = useState([]);
  const [sonuc04goster, setSonuc04] = useState([]);
  const [sonuc05goster, setSonuc05] = useState([]);
  const [sonuc06goster, setSonuc06] = useState([]);
  const [sonuc07goster, setSonuc07] = useState([]);
  const [sonuc081goster, setSonuc081] = useState([]);
  const [sonuc082goster, setSonuc082] = useState([]);
  const [sonuc091goster, setSonuc091] = useState([]);
  const [sonuc092goster, setSonuc092] = useState([]);
  const [sonuc093goster, setSonuc093] = useState([]);
  const [sonuc10goster, setSonuc10] = useState([]);
  const [sonuc11goster, setSonuc11] = useState([]);
  const [sonuc12goster, setSonuc12] = useState([]);
  const location = useLocation();
  const postId = location.pathname.split("/")[2];
  const records = posts.slice();
  //const recordsh = postsh.slice();
  const sonuc19 = 19;
  const sonuc24 = 24;
  const sonuc11 = 11;

  useEffect(() => {
  
  // sunum bilgileri
    const fetchData = async () => {
      //console.log("use effect home calisti");
      try {
        //console.log("postId", postId);
        const res = await axios.get(
          `https://api.recordatifocus.com/sunum-bilgileri/${postId}`
        );
        //console.log("res", res.data);
        setPosts(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
// sunum bilbileri
// 
// Soru 01
    const soruBir = async () => {
      const postId = location.pathname.split("/")[2];

      //console.log("postId", postId);
      const url = `https://api.recordatifocus.com/hippurin-sonuc-01/${postId}`;
      const res = await fetch(url);
      const data = await res.json();
      let outputData = data.map((item) => {
        return {
          cevap: parseInt(item.cevap),
        };
      });
      //console.log(outputData);
      const result = Object.values(outputData).reduce(
        (r, { cevap }) => r + cevap,
        0
      );
      //console.log(result);
      const longeur = data.length;
      //console.log(longeur);
      //setPostsh(data);
      //console.log((result*100)/longeur);

      let sonuc = 100 - (result * 100) / longeur;
      console.log("Soru01 yanlis orani:", sonuc);

      if (sonuc >= sonuc19) {
      	const sonuc01goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/hippurin/Hippurin_Sunum_PTM_MART24_Page_02.jpg"/></div>;
      	setSonuc01(sonuc01goster);
        //console.log(sonuc01goster);
      } else {
      	const sonuc01goster = null;
      	setSonuc01(sonuc01goster);
      }
    };
  
    soruBir();

    
// soru 01
// 
// 
// 
// Soru 02
    const soruIki = async () => {
      const postId = location.pathname.split("/")[2];

      //console.log("postId", postId);
      const url = `https://api.recordatifocus.com/hippurin-sonuc-02/${postId}`;
      const res = await fetch(url);
      const data = await res.json();
      let outputData = data.map((item) => {
        return {
          cevap: parseInt(item.cevap),
        };
      });
      //console.log(outputData);
      const result = Object.values(outputData).reduce(
        (r, { cevap }) => r + cevap,
        0
      );
      //console.log(result);
      const longeur = data.length;
      //console.log(longeur);
      //setPostsh(data);
      //console.log((result*100)/longeur);

      let sonuc = 100 - (result * 100) / longeur;
      console.log("Soru02 yanlis orani:", sonuc);

      if (sonuc >= sonuc19) {
      	const sonuc02goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/hippurin/Hippurin_Sunum_PTM_MART24_Page_04.jpg"/></div>;
      	setSonuc02(sonuc02goster);
        //console.log(sonuc01goster);
      } else {
      	const sonuc02goster = null;
      	setSonuc02(sonuc02goster);
      }
    };
  
    soruIki();
// soru 02
// 
// 
// 
// Soru 03
    const soruUcBir = async () => {
      const postId = location.pathname.split("/")[2];

      //console.log("postId", postId);
      const url = `https://api.recordatifocus.com/hippurin-sonuc-03/${postId}`;
      const res = await fetch(url);
      const data = await res.json();
      let outputData = data.map((item) => {
        return {
          cevap: parseInt(item.cevap),
        };
      });
      //console.log(outputData);
      const result = Object.values(outputData).reduce(
        (r, { cevap }) => r + cevap,
        0
      );
      //console.log(result);
      const longeur = data.length;
      //console.log(longeur);
      //setPostsh(data);
      //console.log((result*100)/longeur);

      let sonuc = 100 - (result * 100) / longeur;
      console.log("Soru031 yanlis orani:", sonuc);

      if (sonuc >= sonuc19) {
      	const sonuc031goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/hippurin/Hippurin_Sunum_PTM_MART24_Page_06.jpg"/></div>;
      	setSonuc031(sonuc031goster);
        //console.log(sonuc01goster);
      } else {
      	const sonuc031goster = null;
      	setSonuc031(sonuc031goster);
      }
    };
  
    soruUcBir();
// soru 03
// 
// 
// 
// Soru 03
    const soruUcIki = async () => {
      const postId = location.pathname.split("/")[2];

      //console.log("postId", postId);
      const url = `https://api.recordatifocus.com/hippurin-sonuc-03/${postId}`;
      const res = await fetch(url);
      const data = await res.json();
      let outputData = data.map((item) => {
        return {
          cevap: parseInt(item.cevap),
        };
      });
      //console.log(outputData);
      const result = Object.values(outputData).reduce(
        (r, { cevap }) => r + cevap,
        0
      );
      //console.log(result);
      const longeur = data.length;
      //console.log(longeur);
      //setPostsh(data);
      //console.log((result*100)/longeur);

      let sonuc = 100 - (result * 100) / longeur;
      console.log("Soru032 yanlis orani:", sonuc);

      if (sonuc >= sonuc19) {
      	const sonuc032goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/hippurin/Hippurin_Sunum_PTM_MART24_Page_08.jpg"/></div>;
      	setSonuc032(sonuc032goster);
        //console.log(sonuc01goster);
      } else {
      	const sonuc032goster = null;
      	setSonuc032(sonuc032goster);
      }
    };
  
    soruUcIki();
// soru 03
// 
// 
// 
// Soru 03
    const soruUcUc = async () => {
      const postId = location.pathname.split("/")[2];

      //console.log("postId", postId);
      const url = `https://api.recordatifocus.com/hippurin-sonuc-03/${postId}`;
      const res = await fetch(url);
      const data = await res.json();
      let outputData = data.map((item) => {
        return {
          cevap: parseInt(item.cevap),
        };
      });
      //console.log(outputData);
      const result = Object.values(outputData).reduce(
        (r, { cevap }) => r + cevap,
        0
      );
      //console.log(result);
      const longeur = data.length;
      //console.log(longeur);
      //setPostsh(data);
      //console.log((result*100)/longeur);

      let sonuc = 100 - (result * 100) / longeur;
      console.log("Soru033 yanlis orani:", sonuc);

      if (sonuc >= sonuc19) {
      	const sonuc033goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/hippurin/Hippurin_Sunum_PTM_MART24_Page_09.jpg"/></div>;
      	setSonuc033(sonuc033goster);
        //console.log(sonuc01goster);
      } else {
      	const sonuc033goster = null;
      	setSonuc033(sonuc033goster);
      }
    };
  
    soruUcUc();
// soru 03
// 
// 
// 
// Soru 04
    const soruDort = async () => {
      const postId = location.pathname.split("/")[2];

      //console.log("postId", postId);
      const url = `https://api.recordatifocus.com/hippurin-sonuc-04/${postId}`;
      const res = await fetch(url);
      const data = await res.json();
      let outputData = data.map((item) => {
        return {
          cevap: parseInt(item.cevap),
        };
      });
      //console.log(outputData);
      const result = Object.values(outputData).reduce(
        (r, { cevap }) => r + cevap,
        0
      );
      //console.log(result);
      const longeur = data.length;
      //console.log(longeur);
      //setPostsh(data);
      //console.log((result*100)/longeur);

      let sonuc = 100 - (result * 100) / longeur;
      console.log("Soru04 yanlis orani:", sonuc);

      if (sonuc >= sonuc24) {
      	const sonuc04goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/hippurin/Hippurin_Sunum_PTM_MART24_Page_07.jpg"/></div>;
      	setSonuc04(sonuc04goster);
        //console.log(sonuc01goster);
      } else {
      	const sonuc04goster = null;
      	setSonuc04(sonuc04goster);
      }
    };
  
    soruDort();
// soru 04
// 
// 
// 
// Soru 05
    const soruBes = async () => {
      const postId = location.pathname.split("/")[2];

      //console.log("postId", postId);
      const url = `https://api.recordatifocus.com/hippurin-sonuc-05/${postId}`;
      const res = await fetch(url);
      const data = await res.json();
      let outputData = data.map((item) => {
        return {
          cevap: parseInt(item.cevap),
        };
      });
      //console.log(outputData);
      const result = Object.values(outputData).reduce(
        (r, { cevap }) => r + cevap,
        0
      );
      //console.log(result);
      const longeur = data.length;
      //console.log(longeur);
      //setPostsh(data);
      //console.log((result*100)/longeur);

      let sonuc = 100 - (result * 100) / longeur;
      console.log("Soru05 yanlis orani:", sonuc);

      if (sonuc >= sonuc24) {
      	const sonuc05goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/hippurin/Hippurin_Sunum_PTM_MART24_Page_14.jpg"/></div>;
      	setSonuc05(sonuc05goster);
        //console.log(sonuc01goster);
      } else {
      	const sonuc05goster = null;
      	setSonuc05(sonuc05goster);
      }
    };
  
    soruBes();
// soru 05
// 
// 
// 
// Soru 06
    const soru06 = async () => {
      const postId = location.pathname.split("/")[2];

      //console.log("postId", postId);
      const url = `https://api.recordatifocus.com/hippurin-sonuc-06/${postId}`;
      const res = await fetch(url);
      const data = await res.json();
      let outputData = data.map((item) => {
        return {
          cevap: parseInt(item.cevap),
        };
      });
      //console.log(outputData);
      const result = Object.values(outputData).reduce(
        (r, { cevap }) => r + cevap,
        0
      );
      //console.log(result);
      const longeur = data.length;
      //console.log(longeur);
      //setPostsh(data);
      //console.log((result*100)/longeur);

      let sonuc = 100 - (result * 100) / longeur;
      console.log("Soru06 yanlis orani:", sonuc);

      if (sonuc >= sonuc19) {
      	const sonuc06goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/hippurin/Hippurin_Sunum_PTM_MART24_Page_16.jpg"/></div>;
      	setSonuc06(sonuc06goster);
        //console.log(sonuc01goster);
      } else {
      	const sonuc06goster = null;
      	setSonuc06(sonuc06goster);
      }
    };
  
    soru06();
// soru 06
// 
// 
// 
// Soru 07
    const soru07 = async () => {
      const postId = location.pathname.split("/")[2];

      //console.log("postId", postId);
      const url = `https://api.recordatifocus.com/hippurin-sonuc-07/${postId}`;
      const res = await fetch(url);
      const data = await res.json();
      let outputData = data.map((item) => {
        return {
          cevap: parseInt(item.cevap),
        };
      });
      //console.log(outputData);
      const result = Object.values(outputData).reduce(
        (r, { cevap }) => r + cevap,
        0
      );
      //console.log(result);
      const longeur = data.length;
      //console.log(longeur);
      //setPostsh(data);
      //console.log((result*100)/longeur);

      let sonuc = 100 - (result * 100) / longeur;
      console.log("Soru07 yanlis orani:", sonuc);

      if (sonuc >= sonuc24) {
      	const sonuc07goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/hippurin/Hippurin_Sunum_PTM_MART24_Page_17.jpg"/></div>;
      	setSonuc07(sonuc07goster);
        //console.log(sonuc01goster);
      } else {
      	const sonuc07goster = null;
      	setSonuc07(sonuc07goster);
      }
    };
  
    soru07();
// soru 07
// 
// 
// 
// Soru 081
    const soru081 = async () => {
      const postId = location.pathname.split("/")[2];

      //console.log("postId", postId);
      const url = `https://api.recordatifocus.com/hippurin-sonuc-08/${postId}`;
      const res = await fetch(url);
      const data = await res.json();
      let outputData = data.map((item) => {
        return {
          cevap: parseInt(item.cevap),
        };
      });
      //console.log(outputData);
      const result = Object.values(outputData).reduce(
        (r, { cevap }) => r + cevap,
        0
      );
      //console.log(result);
      const longeur = data.length;
      //console.log(longeur);
      //setPostsh(data);
      //console.log((result*100)/longeur);

      let sonuc = 100 - (result * 100) / longeur;
      console.log("Soru081 yanlis orani:", sonuc);

      if (sonuc >= sonuc19) {
      	const sonuc081goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/hippurin/Hippurin_Sunum_PTM_MART24_Page_18.jpg"/></div>;
      	setSonuc081(sonuc081goster);
        //console.log(sonuc01goster);
      } else {
      	const sonuc081goster = null;
      	setSonuc081(sonuc081goster);
      }
    };
  
    soru081();
// soru 081
// 
// 
// 
// Soru 082
    const soru082 = async () => {
      const postId = location.pathname.split("/")[2];

      //console.log("postId", postId);
      const url = `https://api.recordatifocus.com/hippurin-sonuc-08/${postId}`;
      const res = await fetch(url);
      const data = await res.json();
      let outputData = data.map((item) => {
        return {
          cevap: parseInt(item.cevap),
        };
      });
      //console.log(outputData);
      const result = Object.values(outputData).reduce(
        (r, { cevap }) => r + cevap,
        0
      );
      //console.log(result);
      const longeur = data.length;
      //console.log(longeur);
      //setPostsh(data);
      //console.log((result*100)/longeur);

      let sonuc = 100 - (result * 100) / longeur;
      console.log("Soru082 yanlis orani:", sonuc);

      if (sonuc >= sonuc19) {
      	const sonuc082goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/hippurin/Hippurin_Sunum_PTM_MART24_Page_19.jpg"/></div>;
      	setSonuc082(sonuc082goster);
        //console.log(sonuc01goster);
      } else {
      	const sonuc082goster = null;
      	setSonuc082(sonuc082goster);
      }
    };
  
    soru082();
// soru 082
// 
// 
// 
// Soru 091
    const soru091 = async () => {
      const postId = location.pathname.split("/")[2];

      //console.log("postId", postId);
      const url = `https://api.recordatifocus.com/hippurin-sonuc-09/${postId}`;
      const res = await fetch(url);
      const data = await res.json();
      let outputData = data.map((item) => {
        return {
          cevap: parseInt(item.cevap),
        };
      });
      //console.log(outputData);
      const result = Object.values(outputData).reduce(
        (r, { cevap }) => r + cevap,
        0
      );
      //console.log(result);
      const longeur = data.length;
      //console.log(longeur);
      //setPostsh(data);
      //console.log((result*100)/longeur);

      let sonuc = 100 - (result * 100) / longeur;
      console.log("Soru091 yanlis orani:", sonuc);

      if (sonuc >= sonuc11) {
      	const sonuc091goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/hippurin/Hippurin_Sunum_PTM_MART24_Page_20.jpg"/></div>;
      	setSonuc091(sonuc091goster);
        //console.log(sonuc01goster);
      } else {
      	const sonuc091goster = null;
      	setSonuc091(sonuc091goster);
      }
    };
  
    soru091();
// soru 091
// 
// 
// 
// Soru 092
    const soru092 = async () => {
      const postId = location.pathname.split("/")[2];

      //console.log("postId", postId);
      const url = `https://api.recordatifocus.com/hippurin-sonuc-09/${postId}`;
      const res = await fetch(url);
      const data = await res.json();
      let outputData = data.map((item) => {
        return {
          cevap: parseInt(item.cevap),
        };
      });
      //console.log(outputData);
      const result = Object.values(outputData).reduce(
        (r, { cevap }) => r + cevap,
        0
      );
      //console.log(result);
      const longeur = data.length;
      //console.log(longeur);
      //setPostsh(data);
      //console.log((result*100)/longeur);

      let sonuc = 100 - (result * 100) / longeur;
      console.log("Soru092 yanlis orani:", sonuc);

      if (sonuc >= sonuc11) {
      	const sonuc092goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/hippurin/Hippurin_Sunum_PTM_MART24_Page_21.jpg"/></div>;
      	setSonuc092(sonuc092goster);
        //console.log(sonuc01goster);
      } else {
      	const sonuc092goster = null;
      	setSonuc092(sonuc092goster);
      }
    };
  
    soru092();
// soru 092
// 
// 
// 
// Soru 093
    const soru093 = async () => {
      const postId = location.pathname.split("/")[2];

      //console.log("postId", postId);
      const url = `https://api.recordatifocus.com/hippurin-sonuc-09/${postId}`;
      const res = await fetch(url);
      const data = await res.json();
      let outputData = data.map((item) => {
        return {
          cevap: parseInt(item.cevap),
        };
      });
      //console.log(outputData);
      const result = Object.values(outputData).reduce(
        (r, { cevap }) => r + cevap,
        0
      );
      //console.log(result);
      const longeur = data.length;
      //console.log(longeur);
      //setPostsh(data);
      //console.log((result*100)/longeur);

      let sonuc = 100 - (result * 100) / longeur;
      console.log("Soru093 yanlis orani:", sonuc);

      if (sonuc >= sonuc11) {
      	const sonuc093goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/hippurin/Hippurin_Sunum_PTM_MART24_Page_22.jpg"/></div>;
      	setSonuc093(sonuc093goster);
        //console.log(sonuc01goster);
      } else {
      	const sonuc093goster = null;
      	setSonuc093(sonuc093goster);
      }
    };
  
    soru093();
// soru 093
// 
// 
// 
// Soru 10
    const soru10 = async () => {
      const postId = location.pathname.split("/")[2];

      //console.log("postId", postId);
      const url = `https://api.recordatifocus.com/hippurin-sonuc-10/${postId}`;
      const res = await fetch(url);
      const data = await res.json();
      let outputData = data.map((item) => {
        return {
          cevap: parseInt(item.cevap),
        };
      });
      //console.log(outputData);
      const result = Object.values(outputData).reduce(
        (r, { cevap }) => r + cevap,
        0
      );
      //console.log(result);
      const longeur = data.length;
      //console.log(longeur);
      //setPostsh(data);
      //console.log((result*100)/longeur);

      let sonuc = 100 - (result * 100) / longeur;
      console.log("Soru10 yanlis orani:", sonuc);

      if (sonuc >= sonuc24) {
      	const sonuc10goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/hippurin/Hippurin_Sunum_PTM_MART24_Page_25.jpg"/></div>;
      	setSonuc10(sonuc10goster);
        //console.log(sonuc01goster);
      } else {
      	const sonuc10goster = null;
      	setSonuc10(sonuc10goster);
      }
    };
  
    soru10();
// soru 10
// 
// 
// 
// Soru 11
    const soru11 = async () => {
      const postId = location.pathname.split("/")[2];

      //console.log("postId", postId);
      const url = `https://api.recordatifocus.com/hippurin-sonuc-11/${postId}`;
      const res = await fetch(url);
      const data = await res.json();
      let outputData = data.map((item) => {
        return {
          cevap: parseInt(item.cevap),
        };
      });
      //console.log(outputData);
      const result = Object.values(outputData).reduce(
        (r, { cevap }) => r + cevap,
        0
      );
      //console.log(result);
      const longeur = data.length;
      //console.log(longeur);
      //setPostsh(data);
      //console.log((result*110)/longeur);

      let sonuc = 110 - (result * 110) / longeur;
      console.log("Soru11 yanlis orani:", sonuc);

      if (sonuc >= sonuc11) {
      	const sonuc11goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/hippurin/Hippurin_Sunum_PTM_MART24_Page_28.jpg"/></div>;
      	setSonuc11(sonuc11goster);
        //console.log(sonuc01goster);
      } else {
      	const sonuc11goster = null;
      	setSonuc11(sonuc11goster);
      }
    };
  
    soru11();
// soru 11
// 
// 
// 
// Soru 12
    const soru12 = async () => {
      const postId = location.pathname.split("/")[2];

      //console.log("postId", postId);
      const url = `https://api.recordatifocus.com/hippurin-sonuc-12/${postId}`;
      const res = await fetch(url);
      const data = await res.json();
      let outputData = data.map((item) => {
        return {
          cevap: parseInt(item.cevap),
        };
      });
      //console.log(outputData);
      const result = Object.values(outputData).reduce(
        (r, { cevap }) => r + cevap,
        0
      );
      //console.log(result);
      const longeur = data.length;
      //console.log(longeur);
      //setPostsh(data);
      //console.log((result*120)/longeur);

      let sonuc = 120 - (result * 120) / longeur;
      console.log("Soru12 yanlis orani:", sonuc);

      if (sonuc >= sonuc24) {
      	const sonuc12goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/hippurin/Hippurin_Sunum_PTM_MART24_Page_29.jpg"/></div>;
      	setSonuc12(sonuc12goster);
        //console.log(sonuc01goster);
      } else {
      	const sonuc12goster = null;
      	setSonuc12(sonuc12goster);
      }
    };
  
    soru12();
// soru 12
// 
// 
// 

  
  
  }, [postId]);


const slide01goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/hippurin/Hippurin_Sunum_PTM_MART24_Page_01.jpg"/></div>;
const slide02goster = sonuc01goster;
const slide03goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/hippurin/Hippurin_Sunum_PTM_MART24_Page_03.jpg"/></div>;
const slide04goster = sonuc02goster;
const slide05goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/hippurin/Hippurin_Sunum_PTM_MART24_Page_05.jpg"/></div>;
const slide06goster = sonuc031goster;
const slide07goster = sonuc04goster;
const slide08goster = sonuc032goster;
const slide09goster = sonuc033goster;
const slide10goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/hippurin/Hippurin_Sunum_PTM_MART24_Page_10.jpg"/></div>;
const slide11goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/hippurin/Hippurin_Sunum_PTM_MART24_Page_11.jpg"/></div>;
const slide12goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/hippurin/Hippurin_Sunum_PTM_MART24_Page_12.jpg"/></div>;
const slide13goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/hippurin/Hippurin_Sunum_PTM_MART24_Page_13.jpg"/></div>;
const slide14goster = sonuc05goster;

const slide15goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/hippurin/Hippurin_Sunum_PTM_MART24_Page_15.jpg"/></div>;
const slide16goster = sonuc06goster;
const slide17goster = sonuc07goster;
const slide18goster = sonuc081goster;
const slide19goster = sonuc082goster;
const slide20goster = sonuc091goster;
const slide21goster = sonuc092goster;
const slide22goster = sonuc093goster;

const slide23goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/hippurin/Hippurin_Sunum_PTM_MART24_Page_23.jpg"/></div>;
const slide24goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/hippurin/Hippurin_Sunum_PTM_MART24_Page_24.jpg"/></div>;
const slide25goster = sonuc10goster;
const slide26goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/hippurin/Hippurin_Sunum_PTM_MART24_Page_26.jpg"/></div>;
const slide27goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/hippurin/Hippurin_Sunum_PTM_MART24_Page_27.jpg"/></div>;
const slide28goster = sonuc11goster;
const slide29goster = sonuc12goster;
const slide30goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/hippurin/Hippurin_Sunum_PTM_MART24_Page_30.jpg"/></div>;
const slide31goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/hippurin/Hippurin_Sunum_PTM_MART24_Page_31.jpg"/></div>;
const slide32goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/hippurin/Hippurin_Sunum_PTM_MART24_Page_32.jpg"/></div>;
const slide33goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/hippurin/Hippurin_Sunum_PTM_MART24_Page_33.jpg"/></div>;
const slide34goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/hippurin/Hippurin_Sunum_PTM_MART24_Page_34.jpg"/></div>;
const slide35goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/hippurin/Hippurin_Sunum_PTM_MART24_Page_35.jpg"/></div>;

const slides = [slide01goster, slide02goster, slide03goster, slide04goster, slide05goster, slide06goster, slide07goster, slide08goster, slide09goster, slide10goster, slide11goster, slide12goster, slide13goster, slide14goster, slide15goster, slide16goster, slide17goster, slide18goster, slide19goster, slide20goster, slide21goster, slide22goster, slide23goster, slide24goster, slide25goster, slide26goster, slide27goster, slide28goster, slide29goster, slide30goster, slide31goster, slide32goster, slide33goster, slide34goster, slide35goster];
var filtered = slides.filter(Boolean);
const listItems = filtered.map((number) =>  <SwiperSlide>{number}</SwiperSlide>);

  return (
    <>
      <Swiper
        effect={"fade"}
        pagination={{
          type: "fraction",
        }}
        keyboard={{
          enabled: true,
        }}
        navigation={true}
        modules={[EffectFade, Navigation, Pagination, Keyboard]}
        className="mySwiper"
      >
        {listItems}
      </Swiper>
  
      {records.map((post) => (
        <div className="container d-none my-4" key={post.id}>
          <div className="sunum-adi">Sunum Adi: {post.title}</div>
  		  <div className="sunum-adi">Sunum Tarihi: {post.tarih}</div>
  		  <div className="sunum-adi">Sunan Kisi: {post.uid}</div>
        </div>
      ))}

<div className={`col d-flex flex-column align-items-center justify-content-center pb-5 footer-${location.pathname.split("/")[2]}`}>

<div className="card mx-2" style={{width: "6rem"}}>
  <a href="https://qr.recordatifocus.com/hippurinkub" className="text-decoration-none text-black" target="_blank"><img alt="" src="https://sorular.recordatifocus.com/sites/api.recordatifocus.com/themes/bootstrap_dofo/images/KUB-HIPPURIN.png" className="img-fluid" /></a>
  <div className="p-1" style={{backgroundColor: "#52BDC7"}}>
    <a href="https://qr.recordatifocus.com/hippurinkub" className="text-decoration-none text-black" target="_blank"><div className="text-center fw-bold">HİPPURİN</div></a>
  </div>
</div>
<p class="pb-5 mx-3 fw-bold footer-link-undefined">www.recordati.com.tr</p>
</div>

  
    </>
  );
};

export default Hipurin;
